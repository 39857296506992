import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginValidation } from './FormValidation';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';


// import Copyright from '../Components/Copyright';
import ReCAPTCHA from 'react-google-recaptcha';
import Loading from './loading/Loading';
import loginImage from '../assets/images/login.png';
import schoolimg from '../assets/images/svm-rashmi.png';
import { Button, Form, Modal } from 'react-bootstrap';
import TermsConditions from './TermsConditions';
import logo from '../assets/images/main-logo.svg';
const Login = () => {
    const { isLoading, setIsLoading,setIsAuthenticated, api_url, userId, setShowSchoolModal } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [loginErrors, setLoginErrors] = useState({});
   
    const [loginData, setLoginData] = useState({
        "user_id": userId,
        "email": location.state?.email_phone,
        "password": ""
    });
    const [passShow, setPassShow] = useState(true);
    const [type, setType] = useState('password')
    const recaptcha = useRef();

    const togglePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
        setPassShow(!passShow)
    };

    const [showOtp, setShowOtp] = useState(false);
    var phoneRegex = /^\d{10}$/;
    var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/
    var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const changeHandler = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (emailPhoneRegex.test(loginData.email)) {
            setLoginErrors({ ...loginErrors, email: '' })
        }
    }, [loginData.email]);

    useEffect(() => {
        if (passwordRegex.test(loginData.password) && loginData.password.length >= 8) {
            setLoginErrors({ ...loginErrors, password: '' })
        }
    }, [loginData.password]);

    useEffect(() => {
        if (phoneRegex.test(loginData.email)) {
            setShowOtp(true)
        } else {
            setShowOtp(false)
        }
    }, [loginData.email])


    //handleOtpLogin
    const handleOtpLogin = async (e) => {
        e.preventDefault();

        const data = {
            "user_id": userId,
            "phone_no": loginData.email,
            "otp_type": "login"
        }
        setIsLoading(true)
        try {
            let res = await axios.post(`${api_url}/login`, data);
            if (res.data.success === 1) {
                let loginData = res.data.data;
                toast.success(res.data.message, {
                    theme: "colored",
                    autoClose: 1500,
                });
                const timer = setTimeout(() => {
                    navigate('/otplogin', { state: { user_id: res.data.data.user_id, phone: data.phone_no, otp: res.data.data.otp, prevPath: window.location.pathname } });
                }, 2000);
                return () => clearTimeout(timer);
            } else {
                toast.error(res.data.message, {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            toast.error("Something Wrong happened", {
                autoClose: 2000
            });
        } finally {
            setIsLoading(false)
        }
    }

    //Login
    const handleLogin = (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        let errors = LoginValidation(loginData);
        setLoginErrors(errors);

        if (Object.keys(errors).length === 0) {
            if (!captchaValue) {
                toast.error('Please verify the reCAPTCHA!', {
                    theme: "colored",
                    autoClose: 2000,
                });
                // alert('Please verify the reCAPTCHA!')
            }
            else {
            setIsLoading(true);
            axios.post(`${api_url}/login`, loginData)
                .then(res => {
                    if (res.data.success === 1) {
                        let loginData = res.data.data;
                        // console.log('cfnsjdncskd', res.data.data)
                        if (loginData.status === 0) {
                            setIsLoading(false);
                            toast.error(res.data.message, {
                                theme: "colored",
                                autoClose: 2000,
                            });
                            const timer = setTimeout(() => {
                                navigate('/otplogin', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: window.location.pathname } });
                            }, 3000);
                            return () => clearTimeout(timer);
                        }
                        else {
                            setIsLoading(false);
                            toast.success(res.data.message, {
                                theme: "colored",
                                autoClose: 2000,
                            });
                            const timer = setTimeout(() => {
                                sessionStorage.setItem('token', (loginData.token));
                                sessionStorage.setItem('username', loginData.name);
                                loginData.school_code !== null && sessionStorage.setItem('school_code', loginData.school_code);
                                sessionStorage.removeItem('guest_id')
                                sessionStorage.setItem('user_id', loginData.user_id);
                                setIsAuthenticated(true)
                                navigate('/')
                                window.scrollTo({ top: 0, behavior: 'instant' })
                            }, 3000);
                            return () => clearTimeout(timer);
                        }
                    } else {
                        setIsLoading(false)
                        toast.error(res.data.message, {
                            autoClose: 2000,
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                    toast.error("axios Error", {
                        autoClose: 2000
                    });
                    setIsLoading(false);
                })
            }
        }
    }
    const [setsearchSchool, setSetsearchSchool] = useState(false)
    const handleClose = () => setSetsearchSchool(false)
    const show = () => setSetsearchSchool(true)
    const [setsearchSchool2, setSetsearchSchool2] = useState(false)
    const handleClose2 = () => setSetsearchSchool2(false)
    const show2 = () => setSetsearchSchool2(true)
    const [setsearchSchool3, setSetsearchSchool3] = useState(false)
    const handleClose3 = () => setSetsearchSchool3(false)
    const show3 = () => setSetsearchSchool3(true)

    return (
        <>
            {isLoading ? <Loading /> : null}
            <main id='main' className=''>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-2 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <section id="login" className='h-100'>
                    <div className="container">
                        <div className="row g-4 mt-md-5">
                            <div className="col-md pt-3">
                                <img src={loginImage} alt='login' className="img-fluid d-none d-md-block" />
                            </div>
                            <div className="col-md bg-white">
                                <div className="border border-1 border-dark rounded-2 p-2 shadow-md user">
                                    <h3 className="text-center pt-3">Login</h3>
                                    <form className=" py-4 px-md-4 px-2  " onSubmit={handleLogin}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email/Mobile No.</label>
                                            <div className="row">

                                                <div className={`${showOtp ? 'col-8' : 'col-12'}`}>
                                                    <input type="text" name="email" onChange={changeHandler} value={loginData.email || ''} className="form-control" id="email" placeholder="Email/Mobile No." />
                                                </div>
                                                {
                                                    showOtp ? <div className="col-4">
                                                        <button type="button" onClick={handleOtpLogin} className="btn border-dark w-100 text-danger px-md-2 px-0 sendotp">Send OTP</button>
                                                    </div> : ''
                                                }
                                            </div>

                                        </div>
                                        <div className="text-danger">{loginErrors.email}</div>
                                        {showOtp && <div className="cursor-pointer text-center mt-2 s-font2"><span className='text-secondary s-font1 ms-4'><span className='text-danger fw-bold'>Or</span> Enter password below</span></div>}
                                        <div className="form-group pt-3 pb-2">
                                            <label htmlFor="pwd">Password</label>
                                            <div className="input-group">
                                                <input type={type} id="password" placeholder="********" className="form-control" name="password" onChange={changeHandler} value={loginData.password || ''} />

                                                <span className="input-group-text bg-white cursor-pointer" onClick={togglePassword}> {passShow ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} </span>
                                            </div>
                                        </div>
                                        <div className="text-danger">{loginErrors.password}</div>
                                        <Link to={'/forgotpassword'} className='text-dark text-decoration-none fw-bold pt-3 mt-3'>Forgot Password</Link>
                                        <div className='mt-3'>
                                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                        </div>
                                        <button type="submit" className="mt-4 btn btn-danger d-block w-100">Login</button>
                                        {
                                            sessionStorage.getItem('school_code') === null ?

                                                <button onClick={() => setShowSchoolModal(true)} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button>
                                                :
                                                <Link to={'/myschool'} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">
                                                    My School
                                                </Link>
                                        }
                                        {/* <button onClick={() => setSetsearchSchool(true)} className="my-3 btn border-dark shadow-btm w-100 text-danger fw-bold">My School</button> */}
                                        <h6 className="mt-3 mb-1">Create an account with <span className="fw-bold">e-Vyapari </span>
                                            <span>
                                                <Link to={'/signup'} className=" text-danger fw-bold text-decoration-none">SignUp</Link></span></h6>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </main>
            <Modal show={setsearchSchool} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title id="schoolmodallabel">My School</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={() => setsearchSchool}>
                        <Form.Group className="mb-3" controlId="schoolCode">
                            <Form.Label>
                                Enter School Code<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="630083" />
                        </Form.Group>{setsearchSchool2 &&
                            <><img src={schoolimg} alt="svm-rashmi" className="img-fluid mb-2 w-100" /><h6 className="text-danger fw-bold">S.V.M. Him Rashmi Parisar</h6><Button variant="danger" className="w-100 text-light fw-bold" onClick={() => { setSetsearchSchool3(true); }}>
                                Confirm School
                            </Button></>}
                        {!setsearchSchool2 &&

                            <Button variant="danger" className="w-100 text-light fw-bold" onClick={() => { setSetsearchSchool2(true) }}>
                                Search
                            </Button>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
            {/* <Modal show={setsearchSchool2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <a href="#schoolmodal">
                            <i className="ti ti-circle-arrow-left fa-solid fs-3"></i>
                        </a>
                        My School
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Form>
                        <Form.Group className="mb-3 text-start" controlId="sch-code-cnf">
                            <Form.Label>
                                Enter School Code<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="630083" />
                        </Form.Group>
                        <img src={schoolimg} alt="svm-rashmi" className="img-fluid mb-2" />
                        <h6 className="text-danger fw-bold">S.V.M. Him Rashmi Parisar</h6>
                        <Button variant="danger" className="w-100 text-light fw-bold" onClick={() => { setSetsearchSchool3(true) }}>
                            Confirm School
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal> */}

            <Modal show={setsearchSchool3} onHide={handleClose3} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <a href="#cfm-schl-modl">
                            <i className="ti ti-circle-arrow-left fa-solid fs-3 me-2"></i>
                        </a>
                        Register as a student
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Form>
                        <Form.Group className="mb-3 text-start" controlId="sch-code-cfm">
                            <Form.Label>
                                School Code<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="630083" />
                        </Form.Group>

                        <Form.Group className="mb-3 text-start" controlId="cfm-num">
                            <Form.Label>
                                Mobile Number<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control type="number" placeholder="0123654789" />
                        </Form.Group>

                        <div className="row">
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label className="text-secondary">
                                        First Name<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Joe" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label className="text-secondary">
                                        Last Name<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Dojo" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="father-name">
                                    <Form.Label className="text-secondary">
                                        Father's Name<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Joe’s Dad" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="mail">
                                    <Form.Label className="text-secondary">
                                        Email<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="email" placeholder="Dojo@gmail.com" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="c-pass">
                                    <Form.Label className="text-secondary">
                                        Create Password<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="password" placeholder="********" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-3" controlId="con-pass">
                                    <Form.Label className="text-secondary">
                                        Confirm Password<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control type="password" placeholder="********" />
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-4" controlId="sel-ste">
                                    <Form.Label>
                                        Select State<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select>
                                        <option>Select State</option>
                                        <option value="1">Himachal Pradesh</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-lg-6 text-start">
                                <Form.Group className="mb-4" controlId="sel-dis">
                                    <Form.Label>
                                        Select District<span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Select>
                                        <option>Select District</option>
                                        <option value="1">Shimla</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Check
                            type="checkbox"
                            id="flexCheckDefault"
                            defaultChecked
                            label={
                                <>
                                    I agree to accept the terms and conditions
                                    <span
                                        className="cursor-pointer text-decoration-none text-danger"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ReadMoreModal"
                                    >
                                        READ.
                                    </span>
                                </>
                            }
                        />

                        {/* <Form.Check
                            type="checkbox"
                            className="text-start pb-4"
                            id="check2"
                            checked
                            label="I agree to accept the terms and conditions  READ."
                        /> */}
                        <Button variant="danger" className="w-100 text-light fw-bold" type="submit">
                            Register
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* <Copyright /> */}

            <TermsConditions />
            <ToastContainer />
        </>
    )
}
// )
// }

export default Login