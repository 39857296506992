import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../layout/Header";
import axios from "axios";
import { toast } from "react-toastify";
import Footer from "../layout/Footer";
import jsPDF from "jspdf";
// import autoTable from 'jspdf-autotable';
import html2canvas from "html2canvas";
// import "../assets/css/tracking.css";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";
import { useGlobalContext } from "../contexts/GlobalContext";
import logo from '../assets/images/main-logo.svg';

const getTargetElement = () => document.getElementById('order_details');

const OrderDetails = (props) => {
  const { api_url, userId, setIsLoading } = useGlobalContext();
  const contentRef = useRef(null); // Ref for the content to be converted to PDF
  // const [html2pdf, setHtml2pdf] = useState(null); // State to hold the dynamically imported html2pdf

  // useEffect(() => {
  //   // Dynamically import html2pdf.js only on the client-side
  //   if (typeof window !== 'undefined') {
  //     import('html2pdf.js')
  //       .then((module) => {
  //         setHtml2pdf(module);
  //       })
  //       .catch((err) => {
  //         console.error('Failed to load html2pdf.js', err);
  //       });
  //   }
  // }, []);



  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState({});
  const [orderedItems, setorderedItems] = useState([]);
  const [orderAddress, setOrderAddress] = useState([]);
  const [orderPaymentInfo, setOrderPaymentInfo] = useState([]);

  const options = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      format: 'letter',
      //   orientation: 'landscape',
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/png',
      qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };
  const getOrderDetails = () => {
    const data = {
      user_id: userId,
      invoice_number: location.state?.invoice_number,
    };
    setIsLoading(true);
    axios.post(`${api_url}/getOrderDetails`, data)
      .then((res) => {
        if (res.data.success === 1) {
          setOrderDetails(res.data.data);
          setorderedItems(res.data.data.ordered_items);
          setOrderAddress(res.data.data.order_address);
          setOrderPaymentInfo(res.data.data.ordered_payment_info);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Axios Error", {
          autoClose: 1500,
          theme: "colored",
        });
        console.log(error);
        setIsLoading(false);
      });
  };

  const cancelOrder = async (invoice_number) => {
    const data = {
      'user_id': userId,
      'invoice_number': invoice_number
    }
    // setIsLoading(true)
    try {
      const res = await axios.post(`${api_url}/cancelOrder`, data);
      if (res.data.success === 1) {
        toast.success(res.data.message, {
          autoClose: 1500,
          theme: "colored",
        });
        const timer = setTimeout(() => {
          window.location.reload(false);
        }, 1000)
        return () => clearTimeout(timer);
      } else {
        toast.error('Something Went Wrong', {
          autoClose: 1500,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Axios Error", {
        autoClose: 1500,
        theme: "colored",
      });
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };

  // const contentRef = useRef(null);

  // const handleDownload = () => {
  //   const contentElement = contentRef.current;


  //   setIsLoading(true)
  //   html2canvas(contentElement)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');

  //       // Create PDF document
  //       const pdf = new jsPDF();
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       console.log(pdfHeight,pdfWidth ,'widh')
  //       // Add image to PDF
  //       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

  //       // Save the PDF
  //       pdf.save('download.pdf');
  //       setIsLoading(false)
  //     })
  //     .catch((error) => {
  //       setIsLoading(false)
  //       console.error('Error generating PDF:', error);
  //     });
  // };
  // const handleDownload = () => {
  //   if (html2pdf && contentRef.current) {
  //     setIsLoading(true); // Show loading indicator while generating PDF

  //     const contentElement = contentRef.current;

  //     const options = {
  //       margin: 0.2,
  //       filename: 'download.pdf',
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  //     };

  //     html2pdf()
  //       .from(contentElement) // Convert contentElement to PDF
  //       .set(options) // Apply options for PDF generation
  //       .save() // Trigger the PDF download
  //       .then(() => setIsLoading(false)) // Hide loading indicator
  //       .catch((error) => {
  //         setIsLoading(false); // Hide loading on error
  //         console.error('Error generating PDF:', error);
  //       });
  //   }
  // };
  const handleDownload = () => {
    if (html2pdf && contentRef.current) {
      setIsLoading(true); // Show loading indicator while generating PDF

      const contentElement = contentRef.current;

      const options = {
        margin: 0.2,
        filename: 'download.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      // Generate PDF as a blob first, then convert to dataURL
      html2pdf()
        .from(contentElement)
        .set(options)
        .save()
        .toPdf()
        .get('blob')
        .then((pdfBlob) => {
          const reader = new FileReader();
          reader.onloadend = function (event) {
            const dataUrl = event.target.result;
            const base64Data = reader.result;
            window.Android.receiveDataUrl(base64Data);
            // Send the dataUrl to your Android app
            // if (window.Android && typeof window.Android.receiveDataUrl === 'function') {
            //   window.Android.receiveDataUrl(dataUrl);
            // } else {
            //   console.error('Android interface not available');
            // }
          };
          reader.readAsDataURL(pdfBlob);
        })
        .then(() => setIsLoading(false)) // Hide loading indicator after PDF generation
        .catch((error) => {
          setIsLoading(false); // Hide loading on error
          console.error('Error generating PDF:', error);
        });
    }
  };

  // const handleDownload = () => {
  //   const contentElement = contentRef.current;
  //   setIsLoading(true);

  //   const options = {
  //     margin: 0.2,
  //     filename: 'download.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //   };

  //   html2pdf().from(contentElement).set(options).save()
  //     .then(() => setIsLoading(false))
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.error('Error generating PDF:', error);
  //     });
  // };
  useEffect(() => {
    getOrderDetails();
  }, []);

  const doc = new jsPDF({
    format: "a4",
    unit: "px",
  });


  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  //Calculations
  var totalMrp = 0,
    totalDiscountedPrice = 0,
    totalCartQty = 0,
    totalDeliveryCharges = 0,
    totalDiscount = 0;
  orderedItems?.forEach((item) => {
    const {
      id,
      product_name,
      qty,
      mrp,
      item_type,
      discounted_price,
      shipping_charges,
      product_color,
      product_size,
      class_title,
      description,
      image,
      folder,
      alt,
    } = item;
    let product_quantity = Number(qty);
    let product_id = id;
    totalMrp += item.mrp * product_quantity;
    totalDiscountedPrice += item.discounted_price * product_quantity;
    totalDiscount = totalMrp - totalDiscountedPrice;
    totalCartQty += product_quantity;
    totalDeliveryCharges += Number(shipping_charges) * product_quantity;
  });
  return (
    <>
      <Header />
      <main id="main" className="space-top py-5 mt-5">
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-4 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <div className="container">
          <div className="mt-2">
            <Link to="/myprofile" className="text-decoration-none text-danger">
              My Profile
            </Link>
            <Link to="/myorders" className="text-decoration-none text-warning">

              <i class="ti ti-chevron-right f-sm"></i> My Orders
            </Link>
            <span>

              <i class="ti ti-chevron-right f-sm"></i> Order Details
            </span>
          </div>
          <div className="mt-3 text-end mb-2">
            {
              (orderDetails.order_status === 1 || orderDetails.order_status === 2) &&
              <button className="btn btn-outline-pink rounded-0 me-2" onClick={() => cancelOrder(orderDetails.invoice_number)}>Cancel Order</button>
            }
            <button className="btn btn-danger rounded-2" onClick={handleDownload}>Download Invoice</button>
          </div>
        </div>

        {/* ORDER */}
        <section ref={contentRef} id="invoice_content" className="">
          <div className="container pt-4 mb-5">
            <div id="order_details" className="bg-white p-3 border-bottom-3 border border-dark rounded-3">
              <div className="row">
                <div className="col-lg-4">
                  <div>
                    <span className="h5 me-3">Shipping Address</span>
                  </div>
                  {orderAddress !== null && (
                    <ul className="list-unstyled">
                      <li>
                        <div className="d-flex gap-2">
                          <div>
                            <span>{orderAddress.name} </span>
                            {orderAddress.address_type === 1 ? (
                              <span className="badge rounded-0 bg-success">
                                Home
                              </span>
                            ) : (
                              <span className="badge rounded-0 bg-warning text-dark">
                                School
                              </span>
                            )}
                          </div>
                          <div className="fw-bold text-dark">
                            {orderAddress.phone_no}
                            {orderAddress.alternate_phone &&
                              `, ${orderAddress.alternate_phone}`}
                          </div>
                        </div>
                      </li>
                      {orderAddress.address_type === 2 && (
                        <li>School Name: {orderAddress.school_name}</li>
                      )}
                      <li>
                        {orderAddress.village && orderAddress.village}
                        {orderAddress.address &&
                          `, ${orderAddress.address}`}
                      </li>
                      <li>
                        {orderAddress.pincode &&
                          `Pincode: ${orderAddress.pincode}`}
                        {orderAddress.post_office &&
                          `, Post Office: ${orderAddress.post_office}`}
                      </li>
                      <li>
                        {orderAddress.city && orderAddress.city}
                        {orderAddress.district && `, ${orderAddress.district}`}
                        {orderAddress.state && `, ${orderAddress.state}`}
                      </li>
                    </ul>
                  )}
                </div>
                <hr className="d-lg-none mt-2" />
                <div className="col-lg-8 d-lg-flex d-block justify-content-center text-start">
                  <div className="table-responsive">
                    <table>
                      <tbody>
                        <tr>
                          <th scope="row">Invoice Number:</th>
                          <td>
                            <span className="">
                              {orderDetails.invoice_number}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Order Date: </th>
                          <td>
                            <span className="">
                              {orderDetails.order_date}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Order Status: </th>
                          <td>
                            <span className="">
                              {(() => {
                                switch (orderDetails.order_status) {
                                  case 1:
                                    return (
                                      <span>
                                        <span className="status-dot bg-warning d-inline-block "></span>
                                        <span>Pending</span>
                                      </span>
                                    )
                                  case 2:
                                    return (
                                      <span>
                                        <span className="status-dot bg-success d-inline-block "></span>
                                        <span>Placed</span>
                                      </span>
                                    )
                                  case 3:
                                    return (
                                      <span>
                                        <span className="status-dot bg-info d-inline-block "></span>
                                        <span>In Process</span>
                                      </span>
                                    )
                                  case 4:
                                    return (
                                      <span>
                                        <span className="status-dot bg-success d-inline-block "></span>
                                        <span>Delivered</span>
                                      </span>
                                    )
                                  case 5:
                                    return (
                                      <span>
                                        <span className="status-dot bg-danger d-inline-block "></span>
                                        <span>Cancelled</span>
                                      </span>
                                    )
                                  default:
                                    return null;
                                }
                              })()}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-start">
                            Transaction Id:
                          </th>
                          <td>
                            <span className="text-start">
                              {orderPaymentInfo?.transaction_id}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr className="d-lg-none mt-2" />
              </div>
            </div>
          </div>

          {/* Product table  */}
          <div className="container">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">Product</th>
                    <th scope="col">Size/Class</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedItems?.map((item, i) => {
                    const {
                      product_name,
                      vendor_name,
                      class_title,
                      qty,
                      mrp,
                      discounted_price,
                    } = item;
                    let product_quantity = Number(qty);
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>
                          <span>{product_name}</span>
                          {/* {vendor_name !== null ? (
                          <span>
                            {" "}
                            (<strong>Sold By</strong> {vendor_name})
                          </span>
                        ) : (
                          <span>
                            {" "}
                            (<strong>Sold By</strong> Evyapari)
                          </span>
                        )} */}
                        </td>
                        <td>{class_title !== null && class_title}</td>
                        <td>
                          <del>₹{mrp}</del> ₹{discounted_price}
                        </td>
                        <td>{product_quantity}</td>
                        <td>{product_quantity * discounted_price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* order total */}
          <div id="" className="container">
            <div className="row">
              <div className="col-md-8 col-12"></div>
              <div className="col-md-4 col-12">
                <div className="">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="card-title h6">
                          Subtotal ({orderedItems?.length} items, qty
                          {totalCartQty})
                        </div>
                        <div className="h6">
                          <strong>
                            ₹{orderDetails?.order_total_without_shipping}
                          </strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="card-title h6">
                          Delivery Charges(Total)
                        </div>
                        <div className="h6">
                          <strong>+ ₹{orderDetails?.shipping_charge}</strong>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="card-title h6">Total Discount</div>
                        <div className="h6">
                          <strong>- ₹{orderDetails?.discount}</strong>
                        </div>
                      </div>
                      <hr className="m-0 mb-1" />
                      <div className="d-flex justify-content-between">
                        <div className="card-title h6">Grand Total</div>
                        <div className="h6">
                          <strong>₹{orderDetails?.grand_total}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4 ">
            <div className="p-4 mb-3 bg-white ">
              <div className="text-center">www.evyapari.com</div>
              <div>
                <div>Registered Name & Address</div>
                <div className="s-font2">
                  V4 Masters, e-vyapari, Bhagat Complex Main Bazar Nadaun,
                  Distt. Hamirpur 177033 (H.P), GSTN: 02IKOPS0284N1ZH, Email:
                  evyapari7@gmail.com
                </div>
                <div className="s-font2 ">
                  <i>
                    Goods once sold are not returnable. All guarantees &
                    warranty subject to seller.
                  </i>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* order tracking  */}
        <section id="order_tracking" className="py-5">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12">
                {orderedItems?.map((item, i) => {
                  const {
                    product_id,
                    item_type,
                    vendor_id,
                    product_name,
                    tracking_status,
                    shipper_name,
                    courier_number,
                    shipping_partner,
                    shipper_address,
                  } = item;

                  return (
                    tracking_status !== 0 ?
                      <div className="card card-stepper border border-2 border-bottom-3 border-dark rounded-3 text-black">
                        <div className="card-body">
                          <div className="d-block d-sm-flex justify-content-between align-items-center mb-3 px-md-5">
                            <div>
                              <h5 className="mb-0 text-start"><span className="">{i + 1}.</span> {product_name}</h5>
                            </div>
                            <div className="">
                              <p className="mb-0">Shipping Partner : <strong>{shipping_partner}</strong></p>
                              <p className="mb-0">
                                Track your order at :{" "}
                                <a className="text-decoration-none" href={`https://${shipper_name}`} target="_blank">Track Here</a>
                              </p>
                              {/* <p className="mb-0">
                              Shipper Address :{" "}
                              <span className="font-weight-bold">
                                {shipper_address}
                              </span>
                            </p> */}
                              <p className="mb-0">
                                Consignment No. :{" "}
                                <span className="font-weight-bold">
                                  {courier_number}
                                </span>
                              </p>
                            </div>
                          </div>
                          {(() => {
                            switch (tracking_status) {
                              case 1:
                                return (
                                  <div class="row d-flex justify-content-center">
                                    <div class="col-12">
                                      <ul id="progressbar" class="text-center">
                                        <li class="active step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              case 2:
                                return (
                                  <div class="row d-flex justify-content-center">
                                    <div class="col-12">
                                      <ul id="progressbar" class="text-center">
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              case 3:
                                return (
                                  <div class="row d-flex justify-content-center">
                                    <div class="col-12">
                                      <ul id="progressbar" class="text-center">
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="step0"></li>
                                        <li class="step0"></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              case 4:
                                return (
                                  <div class="row d-flex justify-content-center">
                                    <div class="col-12">
                                      <ul id="progressbar" class="text-center">
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="step0"></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              case 5:
                                return (
                                  <div class="row d-flex justify-content-center">
                                    <div class="col-12">
                                      <ul id="progressbar" class="text-center">
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                        <li class="active step0"></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              default:
                                return null;
                            }
                          })()}

                          <div className="d-flex justify-content-between top">
                            <div className=" icon-content text-center">
                              {/* <img className="icon" src={require("../assets/images/order_tracking/process.png")}
                                alt=""
                              /> */}
                              <i className="ti ti-clipboard-text fs-3 order-detail-icons" />

                              <div className="d-flex flex-column">
                                <p className="text-center">
                                  In
                                  <br />
                                  Process
                                </p>
                              </div>
                            </div>
                            <div className=" icon-content text-center">
                              <i className="ti ti-replace fs-3 order-detail-icons" />

                              <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                  In
                                  <br />
                                  Production
                                </p>
                              </div>
                            </div>
                            <div className=" icon-content text-center">
                              <i className="ti ti-ship fs-3 order-detail-icons" />



                              <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                  Order
                                  <br />
                                  Shipped
                                </p>
                              </div>
                            </div>
                            <div className=" icon-content text-center">
                              <i className="ti ti-truck-delivery fs-3 order-detail-icons" />

                              <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                  Out for
                                  <br />
                                  Delivery
                                </p>
                              </div>
                            </div>
                            <div className=" icon-content text-center">
                              <i className="ti ti-home-check fs-3 order-detail-icons" />

                              <div className="d-flex flex-column">
                                <p className="font-weight-bold text-center">
                                  Order
                                  <br />
                                  Delivered
                                </p>
                              </div>
                            </div>
                          </div>
                          <Link to='/givereview' onClick={() => window.scrollTo({ top: 0, behavior: 'instant' })} state={{ 'product_id': product_id, 'item_type': item_type, 'vendor_id': vendor_id }}><button className="btn btn-danger">Give Review</button></Link>

                        </div>
                      </div>
                      :
                      null
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default OrderDetails;
