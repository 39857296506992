import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AddressValidation } from './FormValidation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../layout/Header';
import Loading from '../components/loading/Loading';
import ProfileSidebar from '../components/ProfileSidebar';
import logo from '../assets/images/main-logo.svg';


const AddAddress = () => {

  const navigate = useNavigate();
  const { isLoading, setIsLoading, onlyNumbers, api_url, handleStateChange, stateData, districts } = useGlobalContext();
  const [addressErrors, setaddressErrors] = useState({});
  const [addressData, setAddressData] = useState({
    "user_id": sessionStorage.getItem('user_id'),
    "address_type": 1,
    "name": "",
    "phone_no": "",
    "alternate_phone": "",
    "village": "",
    "city": "",
    "state": "",
    "district": "",
    "post_office": "",
    "pincode": "",
    "address": "",
  });
  
  const handleInputs = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAddressData(prev => ({ ...prev, [key]: value }));
  }

  const handleState = (e) => {
    setAddressData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    handleStateChange(e);
  }

  //Add Address
  const submitHandler = (event) => {
    event.preventDefault();
    let errors = AddressValidation(addressData)
    setaddressErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true)
      axios.post(`${api_url}/addAddress`, addressData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
        .then(res => {
          setIsLoading(false)
          toast.success(res.data.message, {
            autoClose: 1500,
            theme: 'colored'
          });
          console.log(res.data.data.id)
          const timer = setTimeout(() => {
            navigate(-1);
            sessionStorage.setItem("addressId",res.data.data.id)
          }, 2000);
          return () => clearTimeout(timer);
        })
        .catch(err => {
          toast.error('Something Wrong Happened', {
            autoClose: 1500,
          });
          setIsLoading(false);
        });
    }
  }
  return (
    <>
      {isLoading ? <Loading /> : null}
      <Header />
      <main id="main" className='mt-5 pt-5 space-top'>
        <div className="eVyapari-content w-100">
          <div className="text-center  mb-2 d-block d-md-none">
            <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
          </div>
        </div>
        <section id="add-new-address-form" className="pb-5 clearfix">
          <div className="container">
            <ProfileSidebar />
            {/*--------------Your Information-form---------*/}
            <div className="float-md-end bill-details w-75">
              <div className=" bg-white border border-1 p-4 rounded-4 border border-dark shadow-btm">
                <div className="d-flex justify-content-between bg-white">
                  <h6 className="font-cart fw-bold">Your Information</h6>
                </div>
                <form className="bg-white">
                  <div className="row">
                    <div className="col-lg-6 text-start">
                      <div className="mb-3">
                        <label htmlFor="fname" className="form-label text-secondary">First Name<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="fname" name='name' onChange={handleInputs} value={addressData.name} placeholder="First Name" />
                        <div className='text-danger s-font2'>{addressErrors.name}</div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 text-start">
                      <div className="mb-3">
                        <label htmlFor="lname" className="form-label text-secondary">Last Name</label>
                        <input type="text" className="form-control" id="lname" placeholder="Raja" />
                      </div>
                    </div> */}
                    <div className="col-lg-6 text-start">
                      <label htmlFor="number" className="form-label text-secondary">Contact Number<span className="text-danger">*</span></label>
                      <div className=" mb-3">
                        <input type="tel" className="form-control " id="number" name='phone_no' max={10} onKeyDown={(e) => onlyNumbers(e)} onChange={handleInputs} value={addressData.phone_no} placeholder="Contact Number" />

                        <div className='text-danger s-font2'>{addressErrors.phone_no}</div>
                      </div>
                    </div>
                    {/* <div className="col-lg-12 text-start">
                      <div className="mb-3">
                        <label htmlFor="place" className="form-label text-secondary">Select Place</label>
                        <input type="text" className="form-control" id="place" placeholder="Place" />
                      </div>
                    </div> */}
                    <div className="col-lg-6 text-start">
                      <div className=" mb-3">
                        <label htmlFor="sel-ste" className="form-label">Select State<span className="text-danger">*</span></label>
                        <select className="form-select " onChange={(e) => handleState(e)} name='state' value={addressData.state} >
                          <option selected>Select State</option>
                          {
                            stateData.map((state, i) => {
                              return (
                                <option key={i} value={state.state_name}>{state.state_name}</option>
                              )
                            })
                          }
                        </select>
                        <div className='text-danger s-font2'>{addressErrors.state}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 text-start">
                      <div className=" mb-3">
                        <label htmlFor="sel-dis" className="form-label">Select District<span className="text-danger">*</span></label>
                        <select className="form-select" name='district' value={addressData.district} onChange={handleInputs} >
                          <option selected>Select District</option>
                          {
                            districts.map((district, i) => {
                              return (
                                <option key={i} value={district}>{district}</option>
                              )
                            })
                          }
                        </select>
                        <div className='text-danger s-font2'>{addressErrors.district}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 text-start">
                      <div className="mb-3">
                        <label htmlFor="post-office" className="form-label text-secondary">Post Office<span className="text-danger">*</span></label>
                        <input type="text" className="form-control text-black" id="post-office" name='post_office' onChange={handleInputs} value={addressData.post_office} placeholder="Post Office" /> <div className='text-danger s-font2'>{addressErrors.post_office}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 text-start">
                      <div className=" mb-3">
                        <label htmlFor="pin-code" className="form-label text-secondary">Pin Code<span className="text-danger">*</span></label>
                        <input type="text" className="form-control text-black" name='pincode' onChange={handleInputs} value={addressData.pincode} id="pin-code" placeholder='Pincode' /> <div className='text-danger s-font2'>{addressErrors.pincode}</div>
                      </div>
                    </div>
                    <div className="col-lg-12 text-start">
                      <div className=" mb-3">
                        <label htmlFor="landmark" className="form-label text-secondary">Landmark / House number / Building name</label>
                        <input type="text" className="form-control text-black" id="pin-code" name='address' onChange={handleInputs} value={addressData.address} placeholder='Landmark / House number / Building name' />
                      </div>
                    </div>
                    <div className="col-lg-6 text-start">
                      <div className=" mb-3">
                        <label htmlFor="ward" className="form-label text-secondary">Village / Ward No.<span className="text-danger">*</span></label>
                        <input type="text" name='village' onChange={handleInputs} value={addressData.village} className="form-control" id="ward" placeholder="Village / Ward No. / Sec." />
                        <div className='text-danger s-font2'>{addressErrors.village}</div>
                      </div>
                    </div>
                    <div className="col-lg-6 text-start">
                      <div className="mb-3 pb-3">
                        <label htmlFor="city" className="form-label text-secondary ">City<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" name='city' onChange={handleInputs} value={addressData.city} id="city" placeholder="City" />
                        <div className='text-danger s-font2'>{addressErrors.city}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2">
                    <div className="col-lg-5">
                      <button type="button" className="btn btn-white border border-1 text-black w-100" onClick={() => navigate(-1)}>Back</button>
                    </div>
                    <div className="col-lg-7">
                      <button className="btn bg-danger border border-dark text-light shadow-btm w-100" type="button" onClick={(e) => submitHandler(e)}>Save</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>


      </main>
      {/* <Copyright /> */}
      <ToastContainer />
    </>
  )
}
export default AddAddress