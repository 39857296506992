import React, { useEffect, useState } from 'react'
import Header from '../layout/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import logo from '../assets/images/main-logo.svg';




const PaymentResponseIcici = () => {
    // Initialize state to hold the parameters
  const [paymentData, setPaymentData] = useState({
    'ID': '',
    'Response Code': '',
    'Unique Ref Number': '',
    'Service Tax Amount': '',
    'Processing Fee Amount': '',
    'Total Amount': '',
    'Transaction Amount': '',
    'Transaction Date': '',
    'Interchange Value': '',
    'TDR': '',
    'Payment Mode': '',
    'SubMerchantId': '',
    'ReferenceNo': '',
    'TPS': '',
    'aes_key': '',
  });

  useEffect(() => {
    // Here you can fetch or parse the data, then set the state accordingly
    const queryParams = new URLSearchParams(window.location.search);

    // Assuming the URL contains query parameters that match the variable names
    setPaymentData({
      'ID': queryParams.get('ID') || '',
      'Response Code': queryParams.get('Response Code') || '',
      'Unique Ref Number': queryParams.get('Unique Ref Number') || '',
      'Service Tax Amount': queryParams.get('Service Tax Amount') || '',
      'Processing Fee Amount': queryParams.get('Processing Fee Amount') || '',
      'Total Amount': queryParams.get('Total Amount') || '',
      'Transaction Amount': queryParams.get('Transaction Amount') || '',
      'Transaction Date': queryParams.get('Transaction Date') || '',
      'Interchange Value': queryParams.get('Interchange Value') || '',
      'TDR': queryParams.get('TDR') || '',
      'Payment Mode': queryParams.get('Payment Mode') || '',
      'SubMerchantId': queryParams.get('SubMerchantId') || '',
      'ReferenceNo': queryParams.get('ReferenceNo') || '',
      'TPS': queryParams.get('TPS') || '',
      'aes_key': queryParams.get('aes_key') || '',
    });
  }, []);
    
    return (
        <>
            <Header />
            <main id='main' className='mt-5 pt-5 space-top'>
                <div className="eVyapari-content w-100">
                    <div className="text-center  mb-2 d-block d-md-none">
                        <Link to={'/'} className="navbar-brand order-md-1 mx-auto" ><img src={logo} alt="e-vyapari-logo" /></Link>
                    </div>
                </div>
                <div className="container">
                    <section className='py-5'>
                        <div className='row'>
                            <div className='col-md-3'></div>
                            <div className='col-md-6 bg-white'>
                                <div className="border b-shadow zoom-2 rounded p-3 pt-0 ">
                                    
                                 <div>
                                    <h4>Payment Response</h4>
                                    <div>
                                        <h5>Transaction Details</h5>
                                        <pre>{JSON.stringify(paymentData, null, 2)}</pre>
                                    </div>
                                    </div>

                                    <div className='text-center mb-3'>
                                        <Link to={'/myorders'} className='btn btn-danger'>View My Orders</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}



export default PaymentResponseIcici